import * as React from "react"
import {graphql } from "gatsby"
import { useInView } from 'react-intersection-observer';

import Navbar from "../components/navbar"
import Reformfooter from "../components/footer"
import Bookaclass from "../components/bookaclass"
import Internalbanner from "../components/internalbanner"
import Profilesection from "../components/profilesection"
import Sectionb from "../homepage/sectionb"
import Findus from "../homepage/findus"
// import Homeswiper from "../homepage/homeswiper"
import GoogleMap from "../components/googlemap"
// import Swipage from "../components/swiper"
import Layout from "../components/layout"
import Sidebar from "../components/sidebar"
import Seo from "../components/seo"
import Yogaback from "../images/yoga1.jpg";
import Shape1 from "../images/shape1.svg";
import Shape2 from "../images/shape2.svg";
import Shape3 from "../images/shape3.svg";
import Shape4 from "../images/shape4.svg";
import Shape5 from "../images/shape5.svg";
import Aboutpic from "../images/studio/about-sally.jpg";
// import Shape6 from "../images/shape6.svg";
// import Shape7 from "../images/shape7.svg";
// import Shape8 from "../images/shape8.svg";
// import Shape9 from "../images/shape9.svg";
// import Shape10 from "../images/shape10.svg";


const Aboutus = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `About Us`
  const { ref, inView } = useInView();

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="About Sally Walsh & Reform Pilates Cork" description="About Reform Pilates Cork"/>
     <Navbar />
     <Internalbanner title="About Us" subtitle="Get to know us" bannerpic={Aboutpic}/>

<section className="internal-page-main gencont" ref={ref}>
  <div className="container">
    <div className="columns is-marginless">

    <div className="column is-8">
<h3>About Reform Pilates</h3><hr/>
<p>Reform Pilates brings a unique range of Pilates fitness classes to Cork city through a dedicated practice studio on Monahan Road near Ballintemple & Blackrock. Led by <strong>Sally Walsh</strong>, who has nearly 20 years of experience and Pilates knowledge to her name, we have a class to suit all ages and fitness levels and look forward to welcoming all new faces to our Studio!
</p>
<h4>What is Reformer Pilates?</h4>
<p>Mat Pilates uses a mat and a person’s own body weight to improve core strength and improve posture. Reformer Pilates builds on that foundation by using a piece of equipment called a reformer to add intensity and provide a smarter and more effective workout. Our Barre Burn class, meanwhile, provides a unique toning and cardio mix for anyone in Cork looking to try something a little different. 
</p>
<h4>Are Reformer Pilates suitable for me?</h4>
<p>Described as the most effective form of Pilates by many, the beauty of Reformer is that it provides a total body workout, but through a low-risk intensity session. 
</p>
<p>Reformer Pilates is suitable for anyone in Cork that is keen to start moving again. Have you an injury that you need to be mindful of? Are you trying to regain fitness after giving birth? Do you need to improve your core strength for another sport? Reformer Pilates is a suitable fitness class for everyone - from a person that cannot do a full gym session or those looking to recover from involvement in high-endurance activities.
</p>
<p>Our classes are smaller in number to ensure individual support for every client, with 1-2-1 sessions available at our Cork city studio to those with more significant requirements.  
</p>
<img src={Shape1} className={inView ? 'shape_1 spin':'shape_1'} alt="shape" />
<img src={Shape2} className={inView ? 'shape_2 spin':'shape_2'} alt="shape" />
<img src={Shape3} className={inView ? 'shape_3 spin':'shape_3'} alt="shape" />
<img src={Shape4} className={inView ? 'shape_4 spin':'shape_4'} alt="shape" />
<img src={Shape5} className={inView ? 'shape_5 spin':'shape_5'} alt="shape" />
      </div>
      
    <div className="column is-3 is-offset-1">
<Sidebar/>
    </div>

  </div>
  </div>
</section>

     <Profilesection />
     <Sectionb />
     <Bookaclass/>
     <Findus />
      <GoogleMap />
     <Reformfooter />
    </Layout>
  )
}

export default Aboutus

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
